import React, {useState} from "react";
import './style.scss';
import CatoLink from '../cato-link';
import CatoImg from "../cato-image";
import Arrow from '../../assets/images/homepage/new_hp/SVG/challenges chevron.svg';
import ArrowHover from '../../assets/images/homepage/new_hp/SVG/challenges chevron - hover.svg';
import {GTag} from '../../common/consts';
import {stripHtml} from "../../common/utils";

const Item = ({icon, buttonText, linkTo}) => {
    const [hover, setHover] = useState(false);

    const handleHover = () => setHover(!hover)

    const handleEvent = (label) => GTag({category: 'Home', action: 'CTA Click', label: label.label})
    return (<CatoLink path={linkTo} className={'item'} onMouseEnter={handleHover} onMouseLeave={handleHover}>
        <CatoImg img={icon} loading={'lazy'} width={118} height={118}/>
        <span dangerouslySetInnerHTML={{__html: buttonText}}/>
        <div className="arrow-wrapper">
            <CatoImg img={hover ? ArrowHover : Arrow}/>
        </div>
        {/*<CatoButton onClick={handleEvent} type={'button'} path={linkTo} className={'cato-button oval green-outline mid'} label={buttonText}/>*/}
    </CatoLink>)
    };

const WanTransformationChallenge = ({title, subheading, items}) => (
    <div className={'wan-challenge'}>
        <div className={'wrapper'}>
            <h2>{stripHtml(title)}</h2>
            {subheading && <p dangerouslySetInnerHTML={{__html:subheading}}/>}
            <div className={'item-wrapper'}>
                {items.map((item, key) =>
                    <Item key={key} icon={item.icon} buttonText={item.buttonText} linkTo={item.link} />
                )}
            </div>
        </div>
    </div>
);

export default WanTransformationChallenge