import React, {useRef} from 'react';
import useOnScreen from '../../hooks/useOnScreen';
// import LazyHydrate from 'react-lazy-hydration';

const LazyLoad = ({children, ssrOnly=false, whenVisible=true}) => {
    const loadRef = useRef();

    const isIntersecting = useOnScreen(loadRef, '250px', true);

    // const childrenItems = isIntersecting ? children : null;

    return (            <div className="lazy-load-wrap" ref={loadRef}>
            { typeof window !== 'undefined' && isIntersecting && children
            }
        </div>
    )
}

export default LazyLoad;