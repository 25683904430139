import React from 'react';
import './style.scss';

const Video = (props) => {
    return (
        <video muted={props.muted} poster={props.poster} autoPlay={props.autoplay} controls={props.controls} loop={props.loop} className="video-component">
            {props.sources.map((source, i) => <source key={`video-source-${i}`} src={source.src} type={source.type} {...source}/>)}
        </video>
    )
}

Video.defaultProps = {
    controls: false,
    autoplay: true,
    muted: true,
    loop: true,
    poster: null
};

export default Video;