// import APJ from '../../../assets/animations/homepage/cato-global-private-backbone/AJP';
// import CatoGlobal from '../../../assets/animations/homepage/cato-global-private-backbone/CatoGlobal';
// import EUROPE from '../../../assets/animations/homepage/cato-global-private-backbone/EUROPE';
// import US from '../../../assets/animations/homepage/cato-global-private-backbone/US';

// import LinesLoop from '../../../assets/animations/homepage/cato-sase-pop/02_Loop';
// import SASEPop from '../../../assets/animations/homepage/cato-sase-pop/01_SASE_Pop';

// import SpaceLoop from '../../../assets/animations/homepage/space/02_Loop';
// import SPACE from '../../../assets/animations/homepage/space/01_SPACE';
// import Text from '../../../assets/animations/homepage/space/03_Text';

import {IS_MOBILE, IS_TABLET} from "../../../common/consts";

const IS_MOBILE_DEVICE = IS_MOBILE || IS_TABLET;

export const ANIMATION_DATA_LIST = [
    {
        value: 'cato-global-private-backbone',
        displayName: 'Global Private Backbone',
        animationTitle: 'The Largest Independent Global Backbone',
        animationFiles: IS_MOBILE_DEVICE ? null : [
            {
                // file: CatoGlobal,
                path: 'assets/animations/homepage/cato-global-private-backbone/CatoGlobal',
                mobilePath: '',
                divId: 'cato-global',
                // increment: 0,
                renderer: 'canvas',
                attrs: {
                    autoplay: false,
                    checkOnScreen: false,
                },
                // mobile: {
                //     path: 'assets/animations/homepage/cato-global-private-backbone/mobile/Mobile_CatoGlobal.png',
                //     attrs: {
                //         autoplay: false,
                //         checkOnScreen: false,
                //     },
                // }
            },
            // {
            //     // file: EUROPE,
            //     path: 'assets/animations/homepage/cato-global-private-backbone/EUROPE 2.0',
            //     mobilePath: '',
            //     divId: 'europe',
            //     // increment: 0,
            //     renderer: 'canvas',
            //     attrs: {
            //         autoplay: false,
            //     }
            // },
            // {
            //     // file: US,
            //     path: 'assets/animations/homepage/cato-global-private-backbone/US 2.0',
            //     mobilePath: '',
            //     divId: 'us',
            //     // increment: 0,
            //     renderer: 'canvas',
            //     attrs: {
            //         autoplay: false,
            //     }
            // },
            // {
            //     // file: APJ,
            //     divId: 'apj',
            //     path: 'assets/animations/homepage/cato-global-private-backbone/AJP 2.0',
            //     mobilePath: '',
            //     // increment: 0,
            //     renderer: 'canvas',
            //     attrs: {
            //         autoplay: false,
            //     }
            // },
        ],
        // event: [{
        //     type: 'onMouseMove',
        //     func: (e, stateCB) => {
        //         const winWidth = document.documentElement.clientWidth;
        //
        //         const winThird = winWidth / 3;
        //
        //         const sections = [
        //             {
        //                 section: 'us',
        //                 winSection: [0, winThird]
        //             },
        //             {
        //                 section: 'europe',
        //                 winSection: [winThird, winThird * 2]
        //             },
        //             {
        //                 section: 'apj',
        //                 winSection: [winThird * 2, winThird * 3]
        //             }
        //         ];
        //
        //
        //         const scrollLeft = e.pageX;
        //
        //         const activeSection = sections.find(section => scrollLeft >= section.winSection[0] && scrollLeft <= section.winSection[1]);
        //
        //         if(activeSection) {
        //             stateCB(activeSection.section)
        //         }
        //
        //
        //     }
        // }]

    },
    {
        value: 'cato-sase-pop',
        displayName: 'SASE PoP',
        animationTitle: 'Scalable and Resilient Traffic Processing in the Cloud',
        type: IS_MOBILE_DEVICE ? 'image' : 'animation',
        animationFiles: [
            {
                // file: SASEPop,
                divId: 'sase-pop',
                path: IS_MOBILE_DEVICE ? 'assets/animations/homepage/cato-sase-pop/mobile/Mobile_SASE_PoP' : 'assets/animations/homepage/cato-sase-pop/01_SASE_Pop',
                // path: 'assets/animations/homepage/cato-sase-pop/01_SASE_Pop',
                increment: 0,
                renderer: 'svg',
                checkOnScreen: IS_MOBILE_DEVICE,
                attrs: {
                    // delay: 0,
                    // destroyOnComplete: true,
                    // loop: IS_MOBILE_DEVICE ? true : false,
                    loop: false,
                    // loop: IS_MOBILE_DEVICE,
                    addIncrement: true,
                    autoplay: false,
                    // stopIncrement: false
                    stopIncrement: IS_MOBILE_DEVICE ? 1 : false,
                    checkOnScreen: !IS_MOBILE_DEVICE,
                }
            },
            {
                // file: LinesLoop,
                divId: 'lines-loop',
                path: IS_MOBILE_DEVICE ? 'assets/animations/homepage/cato-sase-pop/mobile/Mobile_SASE_PoP_Loop' :'assets/animations/homepage/cato-sase-pop/02_Loop (2)',
                // path: 'assets/animations/homepage/cato-sase-pop/02_Loop (2)',
                increment: 1,
                renderer: 'svg',
                attrs: {
                    loop: true,
                    autoplay: false,
                    checkOnScreen: !IS_MOBILE_DEVICE,
                    // destroy
                    // addIncrement
                }
            }
        ],
        text: IS_MOBILE_DEVICE ? {
            title: 'Dynamic Flow Orchestration',
            list: ['Any customer, any edge', 'Contextual policy enforcement', 'Dynamic load balancing', 'Cloud-scale', 'Self-healing', 'Self-maintaining']
        } : null
    },
    {
        value: 'space',
        displayName: 'Single Pass Cloud Engine (SPACE)',
        animationTitle: 'Enforcing A Single Policy, on All Flows, Based on Single Context​',
        animationFiles: [
            {
                // file: SPACE,
                path: IS_MOBILE_DEVICE ? 'assets/animations/homepage/space/mobile/Mobile_Loop' : 'assets/animations/homepage/space/01_SASE_SPACE',
                divId: 'space',
                increment: 0,
                renderer: 'svg',
                attrs: {
                    loop: IS_MOBILE_DEVICE,
                    autoplay: false,
                    // destroyOnComplete: true,
                    addIncrement: true,
                    stopIncrement: IS_MOBILE_DEVICE ? 1 : false,
                }
            },
            {
                // file: SpaceLoop,
                path: IS_MOBILE_DEVICE ? 'assets/animations/homepage/space/mobile/Mobile_Lines' : 'assets/animations/homepage/space/02_SASE_Loop',
                divId: 'space-loop',
                increment: 1,
                renderer: !IS_MOBILE_DEVICE ? 'canvas' : 'svg',
                attrs: {
                    loop: !IS_MOBILE_DEVICE,
                    autoplay: false,
                    addIncrement: IS_MOBILE_DEVICE,
                    // stopIncrement: IS_MOBILE_DEVICE ? 2 : false,
                }
            },
        ],
        textArrangement: IS_MOBILE_DEVICE ? true : null
    }
];