import React, {Component, useState} from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import CatoButton from '../components/cato-button';
import CatoLink from '../components/cato-link';
import CatoImg from '../components/cato-image';
import {canUseWebP, videoSectionMapper, mapCoolInsights, mapGartner} from '../common/utils'
import {GTag, IS_MOBILE, IS_DESKTOP, IS_TABLET, IS_SMALL_DESKTOP, LANG, HOMEPAGE_TRANSLATIONS_DATA} from '../common/consts';
import StructuredData from '../components/structured-data';
import {pickBy, identity} from 'lodash';
import loadable, {lazy} from '@loadable/component';
import LazyLoad from '../components/lazy-load';
import Video from '../components/video';
// import LogoMarquee from '../components/logo-marquee';
// import AnimationSwitchSection from '../features/homepage/components/animation-switch-section';
// import ResourceStrop from '../components/resources-type-select-strip';
// import YoutubeSection from '../c'

// import HeaderVideo from '../assets/animations/homepage/Desktop_CatoHeader';
// import HeaderVideoMobile from '../assets/animations/homepage/Mobile_CatoHeader';

import HeaderVideo from '../assets/video/home/BG.mp4';

import WanTransformationChallenge from "../components/wan-transformation-challange";

import WhiteArrow from '../assets/images/homepage/White_arrow.svg';
import BlackArrow from '../assets/images/homepage/Black_arrow.svg';
// import LogoMarquee from '../components/logo-marquee';
// import GartnerStrip from '../components/gartner-strip';
// import IconCounter from '../components/icon-counter';

import {ANIMATION_DATA_LIST} from "../features/homepage/common/consts";

import MobileBannerVideo from '../assets/video/home/Mobile_CatoHeader_SS.mp4';
import {ReactComponent as BannerSVG} from '../assets/images/homepage/new_hp/SASE.svg';

import '../assets/styles/pages/_home.scss';
import '../assets/styles/responsive/pages/_home-small-desktop.scss';
import '../assets/styles/responsive/pages/_home-tablet.scss';
import '../assets/styles/responsive/pages/_home-mobile.scss';

const LogoMarquee = loadable(() => import('../components/logo-marquee'));
const AnimationSwitchSection = loadable(() => import('../features/homepage/components/animation-switch-section'));
// const AnimationSwitchSection = loadable(() => import('../features/homepage/components/animation-switch-section'), {ssr: false});
const ResourceStrip = loadable(() => import('../components/resources-type-select-strip'));
const YoutubeSection = loadable(() => import('../components/youtube-section'));
// const YoutubeSection = loadable(() => import('../components/youtube-section'), {ssr: false});
const Animation = loadable(() => import('../components/animation'));
const VideoLightbox = loadable(() => import('../components/video-lightbox'));
const StickyBannerCTA = loadable(() => import('../components/sticky-banner-cta'));
// const SASECloudSection = loadableVisibility({cb: () => import('../features/homepage/components/sase-cloud'), fallback: <section className={'sase-cloud'}/>});
const SASECloudSection = loadable(() => import('../features/homepage/components/sase-cloud'));
const GartnerStrip = loadable(() => import('../components/gartner-strip'));
const IconCounter = loadable(() => import('../components/icon-counter'));

class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pauseBanner: false,
            isMobile: false,
            isDesktop: false,
            isTablet: false,
            isSmallDesktop: false,
            webP: true,
            loadTicker: false
        }
    }

    componentDidMount() {
        let newState = {...this.state};

        if(IS_MOBILE) {
            newState = {...newState, isMobile: true}
        } else if(IS_DESKTOP){
            newState = {...newState, isDesktop: true}
        } else if(IS_TABLET) {
            newState = {...newState, isTablet: true}
        } else if (IS_SMALL_DESKTOP) {
            newState = {...newState, isSmallDesktop: true}
        }


        const webP = canUseWebP();

        if(!webP) {
            newState = {...newState, webP: false}
        }

        this.setState({...newState, loadTicker: true})
    }

    genAnimationSection = (icons, button) => {
        const counters = icons ? icons.map(item => {
            return ({
                // count: item.animate,
                count: false,
                icon: item.icon ? item.icon : null,
                finish: item.numberText,
                // float: isFloat,
                start: null,
                step :null,
                interval: null,
                endIcon:null,
                bottomText: item.bottomText

            })}) : null;

        const counterList = counters ? counters.map((item, i) => <li key={`icon-counter-${i}`}><IconCounter {...item} /></li>) : null;

        return <AnimationSwitchSection headline={'Cato SASE Cloud'} animationsDataList={ANIMATION_DATA_LIST}>
            {counterList && <ul>{counterList}</ul>}
            {button.link && <CatoLink className={'explore-sase-button'} path={button.link}><span>{button.buttonText}</span><span className={'arrow'}/></CatoLink>}
        </AnimationSwitchSection>


    };

    handleCTAEvent = (label) =>
    {
        GTag({category: 'Home', action: 'Main CTA Click', label: `${label.label}, ${label.path}`, nonInteraction: false});
    };


    genStructuredOrgData = (structuredOrganizationData) => {
        const org = structuredOrganizationData.organizationData;
        return (
            <StructuredData
                type="Organization"
                data={{
                    url:'https://www.catonetworks.com',
                    legalName: org.structuredLegalName ? org.structuredLegalName : null,
                    foundingDate: org.structuredFoundingDate ? org.structuredFoundingDate : null,
                    founders: org.structuredFounders ? org.structuredFounders.map(i =>
                        (pickBy({
                            '@type': 'Person',
                            "name": i.founderName
                        }, identity))) : null,
                    address: org.structuredAddress ? org.structuredAddress.map(i => (pickBy({
                        '@type': 'PostalAddress',
                        streetAddress: i.streetAddress || null,
                        addressLocality: i.city || null,
                        addressRegion: i.region || null,
                        postalCode: i.postalCode || null,
                        addressCountry: i.country || null,

                    }, identity))) : null,
                    contactPoint: org.structuredContact ? org.structuredContact.map(i => (pickBy({
                        '@type': 'ContactPoint',
                        contactType: i.contactType || null,
                        telephone: i.phoneNumber || null,
                        email: i.email || null,
                        contactOption: i.contactPption ? i.contactOption : null,
                        areaServed: i.areaServed && i.areaServed.length ? i.areaServed.map(j => j.area) : null,
                        availableLanguage: i.languages && i.languages.length ? i.languages.map(j => j.language) : null
                    }, identity))) : null,
                    sameAs: org.structuredSocialAccounts && org.structuredSocialAccounts.length ? org.structuredSocialAccounts.map(i => i.socialAccount) : null
                }}
            />
        )

    };

    mapResourcesByType = (resources) => Object.keys(resources).map(resource => ({
        key: resource,
        items: resources[resource].map(item => (
            {
                img: item.resourceFields.resourceSmallImage || null,
                description: item.title,
                linkLabel: item.resourceFields.resourceButtonText,
                link: item.resourceFields.resourceLink,
                type: item.resourceFields.resourceType
            }
        ))
    }))

    render() {

        const {data} = this.props;
        const {wpPage} = data;
        const {structuredOrganizationData,
            pageSeoTitle,
            hpVideoBanner,
            homepageWANTransformation,
            videoSection,
            resourcesByTypeStrip
        } = wpPage;

        const {isDesktop, isSmallDesktop, isTablet, isMobile, loadTicker} = this.state;

        const animationSection = this.genAnimationSection(hpVideoBanner.homepageIconCounters, hpVideoBanner.homepageButtonCta);

        const youtube = videoSection && videoSection.videos ? {...videoSectionMapper('Watch Cato in Action', videoSection.videos), lightbox: true} : null;

        const resourcesByType = this.mapResourcesByType(resourcesByTypeStrip);

        const stickyBanner = !wpPage.stickyBanner ? false : {
            label: wpPage.stickyBanner.stickyBannerLabel,
            link: wpPage.stickyBanner.stickyBannerLink,
            goldenLabel: wpPage.stickyBanner.goldenButtonText,
            goldenLink: wpPage.stickyBanner.goldenButtonLink,
        };

        const gartner = mapGartner(wpPage, 'dark');

        const structuredOrgData = structuredOrganizationData && structuredOrganizationData.organizationData ? this.genStructuredOrgData(structuredOrganizationData) : null;

        const structuredWebSiteData = <StructuredData type="WebSite" data={{
            name: 'Cato Networks',
            url: 'https://www.catonetworks.com',
            sameAs: structuredOrganizationData && structuredOrganizationData.organizationData.structuredSocialAccounts
            && structuredOrganizationData.organizationData.structuredSocialAccounts.length
                ?
                structuredOrganizationData.organizationData.structuredSocialAccounts.map(i => i.socialAccount)
                : null
        }}/>

        return (
            <Layout className="home"
                    yoastData={wpPage.seo || null}
                    lang={LANG.ENG}
                    whiteMenu={false}
                    seoTitle={pageSeoTitle.pageSeoTitle}
                    title={wpPage.title}
                    translations={HOMEPAGE_TRANSLATIONS_DATA}
                    seoPath={this.props.location.pathname}
            >
                <section className="banner-section video">
                    <div className="video-wrapper">
                        {loadTicker && !isMobile
                            ?
                            <div className="video-with-svg" style={{backgroundColor: '#0b151c'}}>
                                <Video muted={true} autoPlay={true} controls={false} loop={true} sources={[{src: HeaderVideo, type: 'video/mp4'}]}/>
                                <BannerSVG style={{position: 'absolute', top: 0, left: 0}} />
                            </div>
                                :
                            <CatoImg img={data.mobileBannerBG} />

                        }

                    </div>
                    <div className="content-wrap">
                        {hpVideoBanner.bannerHeadline && <h1>{hpVideoBanner.bannerHeadline}</h1>}
                        {hpVideoBanner.bannerButtons.length > 0 && <div className="buttons">
                            {hpVideoBanner.bannerButtons[0] && <CatoButton
                                path={`${hpVideoBanner.bannerButtons[0].link}/`}
                                className="br-4"
                                label={hpVideoBanner.bannerButtons[0].label}
                                onClick={this.handleCTAEvent}
                                icon={BlackArrow}/>}
                            {hpVideoBanner.bannerButtons[1] && <CatoButton
                                path={`${hpVideoBanner.bannerButtons[1].link}/`}
                                className="br-4"
                                label={hpVideoBanner.bannerButtons[1].label}
                                onClick={this.handleCTAEvent}
                                icon={WhiteArrow}/>}
                        </div>
                        }
                    </div>
                </section>
                <section className="customers">
                    <div className="customer-logos">
                        {loadTicker && <LogoMarquee desktop={isDesktop} smallDesktop={isSmallDesktop} mobile={isMobile}/>}
                    </div>
                    <div className="customer-videos">
                        {hpVideoBanner.videosHeadline && <h3>{hpVideoBanner.videosHeadline}</h3>}
                        { hpVideoBanner.videosSlides &&
                        <VideoLightbox
                            type={'column'}
                            playButtonOnHover={true}
                            includeSpacer={false}
                            arrows={!this.state.isMobile}
                            includeShadow={false}
                            slidesToScroll={1}
                            variableWidth={true}
                            slidesToShow={this.state.isMobile ? 1 : 4}
                            centerMode={false}
                            items={hpVideoBanner.videosSlides.map((item) => ({
                                vidId: item.code,
                                title: item.title,
                                vidImg: item.image ? item.image : null
                            }))}
                        />
                        }
                    </div>
                    <div className="quote-wrapper">
                        {hpVideoBanner.quote && <div className="quote" dangerouslySetInnerHTML={{__html: hpVideoBanner.quote}}/>}
                        {hpVideoBanner.quoteSource && <div className="citation">{hpVideoBanner.quoteSource}</div>}
                        {hpVideoBanner.quoteLogo && <CatoImg img={hpVideoBanner.quoteLogo} />}
                    </div>
                </section>
                {structuredWebSiteData}
                {structuredOrgData}
                <LazyLoad ssrOnly={true}>
                    <WanTransformationChallenge title={homepageWANTransformation.wanTransformationTitle}
                                                subheading={homepageWANTransformation.wanTransformationSubheading}
                                                items={homepageWANTransformation.wanTransformationItems} />
                </LazyLoad>
                <LazyLoad>
                    <SASECloudSection headline={hpVideoBanner.homepageCatoSASEHeadline} subheading={hpVideoBanner.homepageCatoSASESubheading} items={hpVideoBanner.homepageCatoSASEIconBoxes}/>
                    {animationSection}
                </LazyLoad>
                <LazyLoad>
                    {
                        youtube && <YoutubeSection {...youtube} />

                    }
                    {
                        (stickyBanner) && <StickyBannerCTA {...{stickyBanner}}/>
                    }
                </LazyLoad>
                <LazyLoad>

                    {
                        gartner && <GartnerStrip klass={'dark'}/>
                    }

                    <ResourceStrip resources={resourcesByType}/>
                </LazyLoad>
            </Layout>
        )
    }
}

export default Home;


export const query = graphql`
    fragment ResourcesByTypeResource on WpResource {
        id
        title
        resourceFields {
            resourceType
            resourceShortDescription
            resourceSmallImage {
                altText
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 263, quality: 100){
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                    publicURL
                }
            }
            resourceButtonText
            resourceLink
        }
    }
    query {
    wpPage(databaseId: { eq: 12 }) {
        ...YoastData
        id
        title
        pageSeoTitle: pageSeoTitle {
            pageSeoTitle
        }
        hpVideoBanner {
            bannerHeadline: hp2021BannerHeadline
            bannerButtons: hp2021BannerButtons {
                label
                link
            }
            videosHeadline: hp2021CustomerVideosHeadline
            videosSlides: hp2021CustomerVideosVideos {
                code
                title
                image {
                    altText
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 394, quality: 90) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                        publicURL
                    }
                }
            }
            quote: hp2021Quote
            quoteSource: hp2021QuoteSource
            quoteLogo: hp2021QuoteLogo {
                altText
                localFile {
                    publicURL
                }
            }
            homepageCatoSASEHeadline: homepagecatosaseheadline
            homepageCatoSASESubheading: homepagecatosasesubheading
            homepageCatoSASEIconBoxes: homepagecatosaseiconboxes {
                icon {
                    altText
                    localFile {
                        publicURL
                    }
                }
                boldText
                lightText
            }
            homepageIconCounters {
                icon {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                            }
                        }
                        publicURL
                    }
                }
                numberText
                bottomText
            }
            homepageButtonCta {
                buttonText
                link
            }
        }
        homepageWANTransformation {
                wanTransformationTitle
                wanTransformationSubheading
                wanTransformationItems {
                    buttonText
                    link
                    icon {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        tryUsStrip {
            tryUsHeadingText
            tryUsButtonText
        }
        resourcesByTypeStrip {
            saseResources {
                ...ResourcesByTypeResource
            }
            whitepaperResources {
                ...ResourcesByTypeResource
            }
            ebookResources {
                ...ResourcesByTypeResource
            }
            demoResources {
                ...ResourcesByTypeResource
            }
            webinarResources {
                ...ResourcesByTypeResource
            }
        }
        structuredOrganizationData {
            organizationData {
                structuredLogo {
                    sourceUrl
                }
                structuredAddress {
                    streetAddress
                    city
                    region
                    country
                    postalCode
                }
                structuredSocialAccounts {
                    socialAccount
                }
                structuredLegalName
                structuredFoundingDate
                structuredFounders {
                founderName
                }
                structuredContact {
                    phoneNumber
                    contactType
                    contactOption
                    languages {
                      language
                    }
                    areasServed {
                      area
                    }
                }
            }
        }
        ...StickyBanner
        ...VideoSection
        ...ResourcesTrio
        ...GartnerStrip
    }
    mobileBannerBG: file(relativePath: { eq: "homepage/new_hp/Mobile_BG.png"}) {
        childImageSharp {
        fluid(maxWidth: 400, quality: 100){
                ...GatsbyImageSharpFluid_withWebp_noBase64
            }
        }
    }
    
}`
